{
    "General": {
        "Video": {
            "Titles": [
                "GIFA 2023",
                "Product Quality Message",
                "GIFA 2019"
            ],
            "NotSupported": "Ihr Browser unterstützt den video-Tag nicht."
        },
        "Address": {
            "Company": "Newform David S. Gower e. K.",
            "Street": "Berliner Allee 65",
            "Location": "64295 Darmstadt",
            "Country": "Deutschland"
        },
        "PhoneNumbers": [
            "+49 (0)6151 3975-210",
            "+49 (0)6151 3975-111"
        ],
        "ReadMore": "Weiterlesen...",
        "Download": "Info herunterladen",
        "Dengge": "Gestaltet & entwickelt von"
    },
    "Navigation": {
        "Home": "Zuhause",
        "Products": "Produkte",
        "News": "Nachrichten",
        "Application": "Anwendung",
        "AboutUs": "Über uns",
        "Privacy": "Privatsphäre & Datenschutz",
        "Contact": "Kontakt",
        "LegalNotice": "Impressum",
        "Back": "Zurück"
    },
    "Home": {
        "Document": {
            "Title": "Newform - Zuhause"
        },
        "Header": {
            "Title": "Willkommen bei Newform",
            "Description": "We consult pertaining to mica lining materials as well as electric- and thermal insulation (also biodegradable) in your foundry. And deliver tear-proof slip-plane mica (muscovite or phlogopite) material for relining of your induction coil furnace once refractory material needs replacement."
        },
        "News": [
            {
                "Title": "GIFA 2023"
            },
            {
                "Title": "GIFA 2019"
            },
            {
                "Title": "GIFA 2015"
            }
        ],
        "Products": [
            {
                "Title": "Combi G"
            },
            {
                "Title": "Foil PGM"
            }
        ]
    },
    "Products": {
        "Document": {
            "Title": "Newform - Produkte"
        },
        "Header": {
            "Title": "Produkte",
            "Description": "Isolationsfolien"
        },
        "Products": [
            {
                "Description": "Beschreibung:",
                "Application": "Anwendung:"
            },
            {
                "Title": "Combi G",
                "Description": "flexibel, dehnbar laminiert feinen Glimmer (Phlogopit) und Calciumsilikatfasern mit einem speziellen Bindemittel Asbest reißfest",
                "Application": "Overlay-Film-Version des alten Pressefeuerfestem Material für Induktionsöfen ohne Transport"
            },
            {
                "Title": "Foil PGM",
                "Description": "Kaltumformung Isolationsmaterial aus Phlogopit (hohe Anwendungstemperatur ) und Moskauer (hohe elektrische Beständigkeit) mit hoher Elastizität und mechanische Stabilität, glasfaserverstärkte und wider reißen",
                "Application": "Overlay-Folie zum Auspressen der alten feuerfesten Materials in der Lieferung von Induktionsöfen"
            }
        ]
    },
    "News": {
        "Document": {
            "Title": "Newform - Nachrichten"
        },
        "Header": {
            "Title": "Nachrichten",
            "Description": ""
        },
        "Years": [
            {
                "Year": "Jahr 2023",
                "News": [
                    {
                        "Title": "",
                        "Metadata": "",
                        "Content": ""
                    }
                ]
            },
            {
                "Year": "Jahr 2019",
                "News": [
                    {
                        "Title": "GIFA 2019",
                        "Metadata": "25. Juni 2019, von: Newform",
                        "Content": "Das GIFA-Team in Halle 10 F77 Judith, Alla, David, Ankit, Uwe und Jan begrüßen Sie zu einem lebhaften Austausch über Glimmerfolien, feuerfestes Material sowie mikroporöse Isolierung, deren Implementierung und Serviceleistungen. Wir servieren Getränke, Espresso und leckeres Spaghetti-Mittagessen."
                    }
                ]
            },
            {
                "Year": "Jahr 2015",
                "News": [
                    {
                        "Title": "Mica slip plane for ferrous and non-ferrous foundries",
                        "Metadata": "31. August 2015, von: Newform",
                        "Content": "Effective application for coreless induction coil furnnaces Sensitive, water-cooled copper coils in induction furnaces are protected by a concrete wall (coil grout) against refractory (thermal insulation). Removal of the worn refractory material with a hammer drill is expensive and dangerous. A tear resistant mica-based layer of mica enables separation from residual (sintered) refractory mass from the concrete wall as it is pushed out and hence protects the coil, enlonges servic..."
                    },
                    {
                        "Title": "GIFA 2015",
                        "Metadata": "29. March 2015, von: Newform",
                        "Content": "At GIFA Newform will participate with its partners from Brazil and Spain.Meet us at Hall 10 booth F77 for a chat, espresso and pasta.Newform: Mica lining for gray iron foundries - Induction coil furnaces.Z-Tech: Al-Refractories for large Al-FurnacesAMV ALEA: Optimized charging software for melting shopFurther: Altona - steel components (high engineering and heat treatment qualification) to heavy industry"
                    }
                ]
            }
        ],
        "News": [
            {
                "Document": {
                    "Title": "Newform - Mica slip plane for ferrous and non-ferrous foundries"
                },
                "Title": "Mica slip plane for ferrous and non-ferrous foundries",
                "Content": [
                    "August 31st, 2015, by: Newform",
                    "Effective application for coreless induction coil furnnaces",
                    "Sensitive, water-cooled copper coils in induction furnaces are protected by a concrete wall (coil grout) against refractory (thermal insulation). Removal of the worn refractory material with a hammer drill is expensive and dangerous. A tear resistant mica-based layer of mica enables separation from residual (sintered) refractory mass from the concrete wall as it is pushed out and hence protects the coil, enlonges service life of the refractory in the crucible and reduces maintenance costs.",
                    "When lining induction coil furnaces, SiO2-based refractory material is inserted locally into the crucible and compacted to form the thermal insulation of Coil from smelt. The approximately 10 cm thick insulation degenerate, depending on the nature of the smelt, after some 200 applications (2-6 weeks with medium frequency Furnaces).",
                    "Removal of the old, used, mass and slag and relining of crucible furnaces with heat insulation demands caution and experience. Getting the coil grout surfaces then sufficiently smooth, so that no defects in the coil wall arise, is labor- and time consuming.",
                    "Newform provides an asbestos-free and biodegradable mica-based layer to serve as slip-plane. The insulating material consists of glas fiber reinforced mica paper laminated with heat-resistant binder to Calcium Silicate paper if required (at larger furnaces, where the wall is rather uneven).",
                    "This, generally 100cm wide and odorless \"wallpaper\" are delivered on a 25 m long roll. They are cut to required size and vertically mounted on the coil side of the crucible furnace (Fig. 1) and fixed at the top with glue (watery and halogen free) or tape. Mica side shows to the interior of the furnace (Fig. 2). The mica coating ensures good sliding between the refractory material and the coil grout.",
                    "Insulation fiber Newform Combi G is a flexible, cold formed laminate comprised of phlogopite and calcium silicate fiber with a special silicon binder. The approximately 2.4-2,5mm thick fiber for the isolation of induction furnaces tolerate temperatures up to 1,050°C, compensates surface unevenness of the, only 2 cm thick, concrete wall and thus reduces cavities.",
                    "Newform Foil PGM, is about 0,4-0.5mm thick,glass-fiber reinforcedand preferably used for smooth interior walls of the furnace to be found in smaller than 2ton furnaces usually.",
                    "Effective and health- and ecologically harmless",
                    "The asbestos-free insulation fibers and insulation foils for induction furnaces are based on muscovite or phlogopite mica (Fig. 3). Mica is a layered silicate with a high electrical resistance. It is heat resistant and in addition resistant to almost all media, such as alkalis, gases, oils and acids.",
                    "According to Hazardous Substances 91/155/EEC Regulation, mica has no health risks, both in storage and during installation and removal. Thus, no special measures have to be taken in respect of the removal of the insulation fibers and insulation films for induction furnaces. The material decomposes in nature into its individual components and can be safely disposed as construction debris and municipal waste landfills.",
                    "Newform has now expanded its product portfolio for the foundries and distributes adjacent products for the melting shop like special software for optimized charging of used materials and alloys. Itoperates in 20 countries with a network of agents and partners, who know the local language and the corresponding national markets.",
                    "Autor und picture provider: David S. Gower, Newform",
                    "www.newform.de",
                    "Tel.: 06151-605210",
                    "mica-based linings are flexible and easy to handle, and have replaced asbestos inmost countries of the world paying attention to non-hazardous products to maintain the health of foundry personell.",
                    "Glasfibre reinforced, non toxic and  generally delivered on Roll (500mm or 1.000mm wide  and 10, 20 or 25 sqm per roll). They serve as:",
                    "<b>Slip-Plane</b> mica with high tensile strength",
                    "<b>Saftey-Layer</b> protecting the coil against refractory material",
                    "<b>Separating-Layer</b> gas diffusion barrier",
                    "<b>Isolation-Layer</b> electrical and thermal protection",
                    "Main products:",
                    "Newform Combi G (Mica/High-temperature paper made of CaSi),",
                    "Newform Foil PGM (Phlogopite/Muscovite mica reinforced with a glasfibre mat)"
                ]
            },
            {
                "Document": {
                    "Title": "Newform - GIFA 2015"
                },
                "Title": "GIFA 2015",
                "Content": [
                    "At GIFA Newform will participate with its partners from Brazil and Spain.<br />Meet us at Hall 10 booth F77 for a chat, espresso and pasta.<br />Newform: Mica lining for gray iron foundries - Induction coil furnaces.<br />Z-Tech: Al-Refractories for large Al-Furnaces<br />AMV ALEA: Optimized charging software for melting shop<br />Further: Altona - steel components (high engineering and heat treatment qualification) to heavy industry"
                ]
            },
            {
                "Document": {
                    "Title": "Newform - GIFA 2019"
                },
                "Title": "GIFA 2019",
                "Content": [
                    "Das GIFA-Team in Halle 10 F77 Judith, Alla, David, Ankit, Uwe und Jan begrüßen Sie zu einem lebhaften Austausch über Glimmerfolien, feuerfestes Material sowie mikroporöse Isolierung, deren Implementierung und Serviceleistungen. Wir servieren Getränke, Espresso und leckeres Spaghetti-Mittagessen.<br /><br />हॉल 10 F77 पर हमारी GIFA टीम, जिसमें शामिल हैं जूडिथ, अला, डेविड, अंकित, उवे और जॉन, निम्न विषयों पर जीवंत विनिमय के लिए आपका स्वागत करती है :-अभ्रक अस्तर, औद्योगिक दुर्दम्य सामग्री, सूक्ष्म छिद्र इन्सुलेशन, इनका कार्यान्वयन और प्रदत्त सेवाएँ।हम पेय, एस्प्रेसो और स्वादिष्ट स्पेगेटी भोजन परोसेंगे।<br /><br />Judith, Alla, David, Ankit, Uwe ve Jan ile Gifa ekibi olarak sizleri Salon 10 F77'de görmekten mutluluk duyacagiz. Sizleri mika astar, refrakter ve mikro gözenekli yalıtım malzemeleri hakkinda fikir alisverisine davet ediyoruz.Cesitli içecekler, espresso ve lezzetli spagetti öğle yemeği servisi sizlei bekliyor olacak.<br /><br />A equipe GIFA no Hall 10 F77 Judith, Alla, David, Ankit, Uwe e Jan,lhes dão as boas vindas para um animado Intercâmbio sobre placas de mica, material de isolamento refratário e micro poroso, bem como o serviço de montagem do mesmo. No stand Será servido bebidas, café e um delicioso almoço com espaguete."
                ]
            }
        ]
    },
    "Application": {
        "Document": {
            "Title": "Newform - Anwendung"
        },
        "Buttons": [
            "Handling Instructions",
            "Editorial"
        ],
        "Header": {
            "Title": "Anwendung",
            "Description": ""
        },
        "Products": [
            {
                "Title": "Newform Combi G",
                "Description": "ist ein asbestfreier und biologisch abbaubarer Isolierstoff, der speziell für die Isoliertechnik in Induktionstiegelöfen entwickelt wurde. Er dient dem Schutz der Spule. Siehe PDF."
            }
        ]
    },
    "AboutUs": {
        "Document": {
            "Title": "Newform - Über uns"
        },
        "Header": {
            "Title": "Über uns",
            "Description": ""
        },
        "Title": "Newform gewinnt neuen Großkunden für seine Glimmerfolien Folien",
        "Description": [
            "Der spezialisierte Anbieter von Mehrschichtfolien für Induktionsöfen ist Gießereielieferant von Großunternehmen in Deutschland und darüber hinaus.",
            "Die Overlay-Folien von Newform Unterstützung in einer sehr effektiver Weg, um den Schmelzvorgang und den Schutz der Induktionsspule aus dem feuerfesten Materials zu erhöhen. Darüber hinaus verstärken sie eine hervorragende Gasdiffusionsbarriere sowie für elektrische Wärmeabschirmung. Asbestfreie Keramik und klassifiziert die Overlay-Folien nach EG-Richtlinie gilt als sicher.",
            "Auch bei der internationalen Expansion Newform hat einen langen Weg zurückgelegt. Durch die Zusammenarbeit mit Agenturen und Händler \"vor Ort\" ist ein lukratives Zugang zu den skandinavischen und osteuropäischen Gießerei-Industrie eröffnet.",
            "Länder wie Kasachstan und Indonesien erweitern seit kurzem die Klientel der Darmstädter Unternehmen auf dem internationalen Markt.",
            "Die Akqusitionserfolge spiegeln auch positiv in den Vertrieb. Von Mai 2010 bis April 2011 Newform steigerte seinen Umsatz gegenüber dem gleichen Zeitraum des Vorjahres um 80 Prozent. Die weltweite Krise der 2008/2009 Newform ist nun überwunden.",
            "Die nächsten Schritte sind, um GIFA Lateinamerika, Spanien und Portugal. dass geeignete Partner zu suchen Newform"
        ],
        "Philosophy": {
            "Title": "Philosophie",
            "Quote": "“Wir bieten hoch qualifizierten Service, kombiniert mit innovativen Produkten für den täglichen Anforderungen unserer Kunden in der Gießerei-Industrie.”",
            "Source": "- David S. Gower | 23. August 2013"
        },
        "Team": {
            "Title": "Das Team kennenlernen",
            "Member": [
                {
                    "Name": "David S. Gower",
                    "Description": "Geschäftsführer"
                },
                {
                    "Name": "David Romain",
                    "Description": "Business Developer International"
                }
            ]
        }
    },
    "Contact": {
        "Document": {
            "Title": "Newform - Kontakt"
        },
        "Header": {
            "Title": "Kontakt"
        },
        "Title": "Bitte zögern Sie nicht uns zu kontaktieren",
        "Description": "Möchten Sie weitere Informationen über unsere Angebote und Dienstleistungen erhalten, senden Sie uns Ihre Anfragen.",
        "ContactInfo": "Kontakt Info",
        "Form": {
            "Name": "Name*",
            "Mail": "E-Mail*",
            "Address": "Adresse",
            "Phone": "Tel./Mobil*",
            "Message": "Nachricht*",
            "Submit": "Senden",
            "Validation": {
                "Name": {
                    "Required": "Name ist erforderlich."
                },
                "Mail": {
                    "Required": "E-Mail ist erforderlich.",
                    "Valid": "E-Mail ist ungültig."
                },
                "Message": {
                    "Required": "Nachricht ist erforferlich."
                },
                "Phone": {
                    "Required": "Tel./Mobil ist erforderlich.",
                    "Valid": "Tel./Mobil ist ungültig."
                }
            },
            "Response": {
                "Success": [
                    "Die Nachricht wurde erfolgreich verschickt.",
                    "Wir kontaktieren Sie in kürze"
                ],
                "Fail": [
                    "Ein Fehler ist aufgetreten!",
                    "Versuchen Sie es später nochmal oder kontaktieren Sie uns per E-Mail."
                ],
                "Ok": "Ok"
            }
        }
    },
    "Footer": {
        "Contact": {
            "Title": "Kontakt"
        },
        "Social": {
            "Title": "Soziale Medien:",
            "Links": [
                "LinkedIn"
            ]
        },
        "Newform": {
            "Title": "Newform"
        },
        "Affiliates": {
            "Title": "Partner"
        }
    },
    "Privacy": {
        "Document": {
            "Title": "Newform - Datenschutzerklärung"
        },
        "Header": {
            "Title": "Datenschutzerklärung",
            "Description": "Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) auf dieser Webseite auf. Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „personenbezogene Daten“ oder deren „Verarbeitung“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO)."
        },
        "Responsibility": {
            "Title": "Verantwortlicher"
        },
        "Datatypes": [
            "Arten der verarbeiteten Daten",
            "Kontaktdaten (z.B. E-Mail, Name)"
        ],
        "Purpose": {
            "Title": "Zweck der Datenverabeitung",
            "Descriptions": [
                "Newform David S. Gower verarbeitet Ihre Daten unter Berücksichtigung der Datenschutz-Grundverordnung (DSGVO) sowie des Bundesdatenschutzgesetzes (BDSG).",
                "Die aufgezeichneten Daten werden nur für diese Zwecke verwendet und werden nicht an Datensammler oder ähnliche weitergegeben."
            ],
            "Purposes": [
                "Kontaktaufnahme und Kontaktverwaltung",
                "Bei der Kontaktaufnahme mit Newform David S. Gower (per Kontaktformular, Telefon oder E-Mail) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Bei Kontaktaufnahme über das Kontaktformular, wird eine automatisch generierte E-Mail an die entsprechende Kontakt-Adresse gesendet. Newform David S. Gower hält es sich vor, den Nutzer auf seinem präferierten Wege zu kontaktieren. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt."
            ]
        },
        "Duration": {
            "Title": "Dauer der Datenspeicherung",
            "Description": "Ihre Daten werden gelöscht, sobald diese für die Verarbeitung der oben genannten Zwecke nicht mehr erforderlich sind. Newform David S. Gower speichert Ihre Daten, sofern wir dazu gesetzlich verpflichtet sind. Diese Pflichten leiten sich u.a. aus dem HGB und AO ab."
        },
        "ThirdParties": {
            "Title": "Datenübermittlung an Dritte",
            "Description": "Die von Newform David S. Gower erhobenen Daten werden nicht an Dritte weitergegeben."
        },
        "ThirdCountries": {
            "Title": "Datenübermittlung in Drittländer",
            "Description": "Die von Newform David S. Gower erhobenen Daten werden nicht an einen Dienstleister außerhalb des EU/EWR Raumes weitergegeben."
        },
        "Complaints": {
            "Title": "Beschwerderecht",
            "Description": [
                "Sie haben das Recht, sich mit einer Beschwerde an den oben genannten Datenschutzbeauftragten oder eine Datenschutzaufsichtsbehörde zu wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für Newform David S. Gower als verantwortliche Stelle zuständige Behörde. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie ",
                "hier."
            ],
            "Rights": [
                "‍Ihre Betroffenenrechte",
                "‍Unter den angegebenen Kontaktdaten des Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:",
                "Auskunft über Ihre bei Newform David S. Gower gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),",
                "Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),",
                "Löschung Ihrer bei Newform David S. Gower gespeicherten Daten (Art. 17 DSGVO),",
                "Einschränkung der Datenverarbeitung, sofern Newform David S. Gower Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen darf (Art. 18 DSGVO),",
                "Widerspruch gegen die Verarbeitung Ihrer Daten bei Newform David S. Gower (Art. 21 DSGVO) und Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit Newform David S. Gower abgeschlossen haben (Art. 20 DSGVO).",
                "Sofern Sie Newform David S. Gower eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen."
            ]
        },
        "Changes": {
            "Title": "Änderungen der Datenschutzerklärung",
            "Description": "Newform David S. Gower behält es sich vor, die Datenschutzerklärung zu ändern, um sie an geänderte Rechtslage oder bei Änderungen des Dienstes sowie der Datenverarbeitung anzupassen. Wir bitten Sie daher im Zweifel die Datenschutzerklärung erneut zu prüfen, bevor Sie personenbezogene Daten an uns übermitteln."
        },
        "Time": "Stand August 2021"
    },
    "LegalNotice": {
        "Document": {
            "Title": "Newform - Impressum"
        },
        "Header": {
            "Title": "Impressum"
        },
        "ResponsibilityTitle": {
            "Inhaber": "Inhaber",
            "Verantwortlich": "Verantwortlich i. S. d. Teledienstgesetzes:",
            "Amtsgericht": "Amtsgericht:",
            "VATNumber": "USt.-ID-Nr.:",
            "Telefon": "Telefon:",
            "Telefax": "Telefax:"
        },
        "Responsibility": {
            "Title": "Newform David S. Gower e. K.",
            "Inhaber": "David S. Gower",
            "Verantwortlich": "David S. Gower",
            "Amtsgericht": "Amtsgericht Darmstadt, HRA 6258",
            "VATNumber": "DE 111512978",
            "Telefon": "+49 (0)6151 3975-210",
            "Telefax": "+49 (0)6151 3975-111"
        },
        "Disclaimer": {
            "Title": "Disclaimer:",
            "Inhalt": [
                "Newform David S. Gower ist bemüht, auf dieser Website bereitzustellen, genaue und vollständige Informationen zur Verfügung. Verantwortung oder Haftung für die Informationen, insbesondere für die Vollständigkeit, Richtigkeit und Aktualität, aber nicht davon ausgegangen werden kann.",
                "Jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen, wird ausgeschlossen, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit des Newform David S. Gower oder ihrer Erfüllungsgehilfen beruht.",
                "Die Newform David S. Gower Website enthält Links zu anderen Websites. Newform David S. Gower ist nicht für den Inhalt dieser anderen Websites nicht verantwortlich und distanziert sich ausdrücklich davon. Newform David S. Gower behält sich das Recht vor, Änderungen oder Ergänzungen ohne vorherige Ankündigung die bereitgestellten Informationen."
            ]
        }
    }
}